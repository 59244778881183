import { Component, Input } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { CategoriesService } from "@service/categories.service";
import { MobileService } from "@service/mobile.service";
import { ScrollService } from "@service/scroll.service";
import { StateService } from "@service/state.service";
import { UserService } from "@service/user/user.service";
import { filter } from "rxjs";

@Component({
    selector: 'create-entry-button-with-tooltip',
    templateUrl: './create-entry-button-with-tooltip.component.html',
    styleUrls: ['./create-entry-button-with-tooltip.component.scss']
})

export class CreateEntryButtonWithTooltipComponent {
    @Input()
    public isMobileNavbar: boolean = false

    public showCreateTooltip: boolean = false;

    public currentUrl: string;

    constructor(
        public mobileService: MobileService,
        public scrollService: ScrollService,
        public userService: UserService,
        public stateService: StateService,
        public categoriesService: CategoriesService,
        public router: Router
    ) {
        this.stateService.stateDataChanged.subscribe(() => {
            this.showCreateTooltip = false;
        });

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.currentUrl = event.url;
        });
    }

    public get getIsMobileNavbar() {
        return this.isMobileNavbar;
    }

    public isCreateActive(): boolean {
        return this.currentUrl && this.currentUrl.includes('/create');
    }
}
