<div class="notification-bell unselectable" [class.is-mobile-navbar]="getIsMobileNavbar" fxLayout="row"
  fxLayoutAlign="center center" style="position: relative; cursor: pointer;"
  (appLoginClickGuard)="mobileService.isMobile ? notificationsDialogService.open() : notificationService.toggleNotificationsOpen()">

  <i class="bell" class="fas fa-bell" *ngIf="!getIsMobileNavbar; else mobileNavbarNotificationsBell"></i>

  <div class="notifications-span" *ngIf="notificationService.notifications && notificationService.notifications.length">
  </div>


  <ng-template #mobileNavbarNotificationsBell>
    <div class="navbtn">
      <div>
        <i class="far fa-bell"></i>
      </div>
    </div>
  </ng-template>
</div>


<!-- notifications tooltip -->
<div class="tooltip" (clickOutside)="onClickedOutside($event)" [delayClickOutsideInit]="true"
  *ngIf="notificationService.areNotificationsOpen">

  <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" fxLayoutAlign="space-between center"
    class="entry-title">
    <div *ngIf="notificationService.notifications.length == 0">Keine Benachrichtigungen</div>
    <div *ngIf="notificationService.notifications.length > 0">Benachrichtigungen</div>
    <button *ngIf="notificationService.notifications.length > 0" (click)="notificationService.markAllAsRead()">Alle
      entfernen</button>
  </div>

  <div class="entry" *ngFor="let notification of notificationService.notifications"
    (click)="notificationService.click(notification)" fxLayout="row">

    <!-- profile image -->
    <div
      *ngIf="notification && notification.sender_frontend_user && notification.sender_frontend_user.profileImage; else noProfileImage"
      class="profileimage" fxFlex="6">
      <img style="width:45px; height: 45px;" [src]="notification.sender_frontend_user.profileImage" />
    </div>

    <!-- icon -->
    <ng-template #noProfileImage>
      <div class="profileicon" fxFlex="6">
        <i class="fas {{ notification.icon }} fa-stack-1x"></i>
      </div>
    </ng-template>

    <div fxFlex="94">
      <!-- headline -->
      <div class="noti-text">
        <b>{{ notification.headline }}</b>
      </div>
      <!-- text -->
      <div class="noti-text">
        {{ notification.description }}
      </div>
    </div>
  </div>
</div>