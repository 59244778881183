<ngx-loading [show]="loading"
  [config]="{ primaryColour: '#ffffff', secondaryColour: '#dedede', tertiaryColour: '#cdcdcd' }"></ngx-loading>

<app-header [class.no-z-index]="isDialogOpen"></app-header>

<div>
  <router-outlet></router-outlet>
</div>

<mobile-navbar *ngIf="mobileService.isMobile"></mobile-navbar>

<app-dsgvo-banner></app-dsgvo-banner>