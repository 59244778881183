<button class="orange-button create-btn mobileGrowBtn" (click)="showCreateTooltip = !showCreateTooltip"
    (clickOutside)="showCreateTooltip = false" *ngIf="!getIsMobileNavbar; else createEntryButtonIcon">
    Beitrag erstellen
</button>


<ng-template #createEntryButtonIcon>
    <button class="navbtn create-btn" [ngClass]="{ 'active': isCreateActive() }"
        (click)="showCreateTooltip = !showCreateTooltip" (clickOutside)="showCreateTooltip = false">
        <div>
            <i class="fas fa-plus-circle"></i>
        </div>
    </button>
</ng-template>

<!-- global create button tooltip -->
<div *ngIf="showCreateTooltip" class="createTooltip">

    <div class="entry" (appLoginClickGuard)="stateService.goto('/blog/create',{},true,true)">
        <i class="fas fa-list-alt"></i>
        <div>Blog-Beitrag erstellen</div>
    </div>

    <div class="entry" (appLoginClickGuard)="stateService.goto(
            '/blog/create',
            {preselected:{category:categoriesService.eventcat}},
            true,
            true
          )">
        <i class="far fa-calendar-check"></i>
        <div>Veranstaltung erstellen</div>
    </div>

    <div class="entry" (appLoginClickGuard)="stateService.goto(
            '/blog/create',
            {preselected:{category:categoriesService.videocat}},
            true,
            true
          )">
        <i class="fas fa-video"></i>
        <div>Video veröffentlichen</div>
    </div>

    <div class="entry" (appLoginClickGuard)="stateService.goto('/market/create',{},true,true)">
        <i class="fa fa-euro-sign"></i>
        <div>Zimmereibedarf verkaufen</div>
    </div>

    <div class="entry" (appLoginClickGuard)="stateService.goto(
            '/market/create',
            {preselected:{advertisement_type:'SEARCH'}},
            true,
            true
          )">
        <i class="fas fa-shopping-cart"></i>
        <div>Zimmereibedarf suchen</div>
    </div>

    <div class="entry" (appLoginClickGuard)="stateService.goto('/job/create',{},true,true)">
        <i class="fas fa-user-plus"></i>
        <div>Stellenanzeige posten</div>
    </div>

    <div class="entry" (appLoginClickGuard)="stateService.goto('/job/create',{},true,true)">
        <i class="fas fa-search-plus"></i>
        <div>Stellengesuch posten</div>
    </div>

</div>