<mat-dialog-content>
  <div class="notifications-headline-row">
    Mein Menü
    <i (click)="close()" class="fal fa-times-circle"></i>
  </div>

  <div *ngIf="!UserService.isLoggedIn">
    <app-login></app-login>
  </div>

  <div class="notifications-row">
    <ng-container *ngIf="UserService.isLoggedIn">
      <div class="notifications-row-item profile-item" *ngFor="let item of burgerDialogMenuItems.slice(0, -1)">
        <div class="content">
          <div class="notifications-row-item-content">
            <app-navigation-button [smallicon]="true" [navigationItem]="item"></app-navigation-button>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="notifications-row-item default-item" *ngFor="let item of secondMenuItems">
      <div class="content">
        <div class="notifications-row-item-content">
          <app-navigation-button [smallicon]="true" [navigationItem]="item"></app-navigation-button>
        </div>
      </div>
    </div>

    <div class="menu-footer">
      <div *ngFor="let item of MenuStructureService.peripheralMenuStructure" [routerLink]="item.target" (click)="goto()"
        class="menu-footer-item">
        {{item.caption}}
      </div>
    </div>

    <ng-container *ngIf="UserService.isLoggedIn">
      <div class="notifications-row-item logout-item" *ngFor="let item of burgerDialogMenuItems.slice(-1)">
        <div class="content">
          <div class="notifications-row-item-content">
            <app-navigation-button [smallicon]="true" [navigationItem]="item"></app-navigation-button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>




</mat-dialog-content>