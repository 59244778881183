import { Component, Input } from '@angular/core'
import { MobileService } from '@service/mobile.service'
import { NotificationService } from '@service/notification.service'
import { NotificationsDialogService } from '@service/notifications-dialog.service'

@Component({
  selector: 'app-notifications-bell',
  templateUrl: './notifications-bell.component.html',
  styleUrls: ['./notifications-bell.component.scss'],
})
export class NotificationsBellComponent {
  @Input()
  public isMobileNavbar: boolean = false

  constructor(
    public notificationService: NotificationService,
    public notificationsDialogService: NotificationsDialogService,
    public mobileService: MobileService
  ) { }


  public onClickedOutside(event) {
    if (this.notificationService.areNotificationsOpen) {
      this.notificationService.closeNotifications()
    }
  }

  public get getIsMobileNavbar() {
    return this.isMobileNavbar;
  }
}
