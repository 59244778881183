import { Location } from "@angular/common";
import { Component, HostListener, OnInit } from "@angular/core";
import { DrawerService } from "@service/drawer.service";
import { MenuStructureService } from "@service/menu-structure/menu-structure.service";
import { NotificationService } from "@service/notification.service";
import { NotificationsDialogService } from "@service/notifications-dialog.service";
import { ScrollService } from "@service/scroll.service";
import { StateService } from "@service/state.service";

@Component({
    selector: 'mobile-navbar',
    templateUrl: './mobile-navbar.component.html',
    styleUrls: ['./mobile-navbar.component.scss']
})

export class MobileNavbarComponent {
    public currentUrl: string;
    public isHidden = false;
    public lastScrollTop = 0;

    constructor(
        public scrollService: ScrollService,
        public drawerService: DrawerService,
        public menuStructureService: MenuStructureService,
        public location: Location,
        public notificationsDialogService: NotificationsDialogService,
        public notificationService: NotificationService,
        public stateService: StateService
    ) {
    }

    public goBack() {
        this.location.back();
    }

    @HostListener('window:scroll', [])
    onWindowScroll(): void {
        const scrollOffset = document.documentElement.scrollTop;

        if (scrollOffset > this.lastScrollTop) {
            this.isHidden = true;
        } else if (scrollOffset < this.lastScrollTop) {
            this.isHidden = false;
        }

        this.lastScrollTop = scrollOffset <= 0 ? 0 : scrollOffset;
    }
}