<!-- one button that does not link to a state (instead to another page or a custom action) - should have no router link so wont be shown as active -->
<div class="navbtn" *ngIf="navigationItem.externalTarget || navigationItem.action" (click)="openExternalCustom()"
  [class.smallicon]="smallicon" fxLayout="row" [fxLayoutAlign]="centerContent ? 'center center' : 'start center'"
  fxLayoutGap="10px" fxLayout.gt-md="column" fxLayoutAlign.gt-md="center center" fxLayoutGap.gt-md="5px">

  <!-- clickguard - positioned absolute -->
  <div *ngIf="navigationItem.onlyLoggedIn && !userService.isLoggedIn" class="click-login-wall" appLoginClickGuard></div>

  <!-- icon if any -->
  <i *ngIf="navigationItem.icon_type == 'FA'" class="{{ navigationItem.icon }} navbtngraphic"
    style="text-align: center;"></i>

  <!-- img icon if any -->
  <img *ngIf="navigationItem.icon_type == 'IMG'" [src]="navigationItem.icon" class="navbtngraphic"
    style="text-align: center;" />

  <!-- text -->
  <div *ngIf="!noText" class="navbtntext">
    {{ navigationItem.caption }}
  </div>
</div>

<!-- and one button that does link to a state - should have router link so can be shown as active -->
<div class="navbtn" *ngIf="navigationItem.target" [routerLink]="navigationItem.target" routerLinkActive="active"
  (click)="doAlways()" [class.smallicon]="smallicon" fxLayout="row"
  [fxLayoutAlign]="centerContent ? 'center center' : 'start center'" fxLayoutGap="10px" fxLayout.gt-md="column"
  fxLayoutAlign.gt-md="center center" fxLayoutGap.gt-md="5px">

  <!-- clickguard - positioned absolute -->
  <div *ngIf="navigationItem.onlyLoggedIn && !userService.isLoggedIn" class="click-login-wall" appLoginClickGuard></div>

  <!-- icon if any -->
  <i *ngIf="navigationItem.icon_type == 'FA'" class="{{ navigationItem.icon }} navbtngraphic"
    style="text-align: center;"></i>

  <!-- img icon if any -->
  <img *ngIf="navigationItem.icon_type == 'IMG'" [src]="navigationItem.icon" class="navbtngraphic"
    style="text-align: center;" />

  <!-- text -->
  <div *ngIf="!noText" class="navbtntext">
    {{ navigationItem.caption }}
  </div>
</div>