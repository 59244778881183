import { Serialize } from 'aautil'

export class StateParamFilter {

  @Serialize({
    to: { map: att => (att == 'true' || att == true || att == 1 ? 1 : 0) },
  })
  offer?: boolean

  @Serialize({
    to: { map: att => (att == 'true' || att == true || att == 1 ? 1 : 0) },
  })
  search?: boolean

  @Serialize({
    to: { map: att => (att == 'true' || att == true || att == 1 ? 1 : 0) },
  })
  new?: boolean

  @Serialize({
    to: { map: att => (att == 'true' || att == true || att == 1 ? 1 : 0) },
  })
  used?: boolean

  @Serialize()
  zip_city?: string

  @Serialize()
  search_string?: string

  @Serialize({
    to:{map:(value:string)=>{
      if(!value)
        return null

      let p = parseInt(value)
      p*=100
      return p+""
    }},
  })
  price_from?: string

  @Serialize({
    to:{map:(value:string)=>{
      if(!value)
        return null

      let p = parseInt(value)
      p*=100
      return p+""
    }},
  })
  price_to?: string

  @Serialize()
  marketplace_category_id?: number

  @Serialize()
  blog_category_id?: number

  @Serialize()
  wiki_category_id?: number

  @Serialize()
  job_category_id?: number

  @Serialize()
  radius_search_km?: number

  @Serialize()
  pagination?: any

  @Serialize()
  order?: any

  public fromStateParams(stateParams: any): any {
    const keys = Object.keys(stateParams)

    for (const k of keys) {
      this[k] = stateParams[k]
    }
  }

  [key: string]: any
}
