import { Serialize, InFormGroup, SerializationService } from 'aautil'
import { Validators, FormGroup, AbstractControlOptions } from '@angular/forms'
import { ComputedImage } from '@model/computed-image'
import { Contactperson } from '@model/contactperson'
import { Profile } from '@interface/profile'
import { standardvalidators } from '@configuration/standard-validators';
import { StaticHelper } from '../../misc/static-helper';
import { GMapAddress } from '@model/gmap-address';
import { ProfileTypeIdentifier } from '@enum/profile-type-identifier'
import { NgxGalleryImage } from 'ngx-gallery-9'
import { deserializePostMapper, Post } from '@model/post'

export function type_definition_VALID() {
  return (group: FormGroup): { [key: string]: any } => {

    const type = group.controls.type.value
    const type_definition = group.controls.type_definition.value
    const type_sector = group.controls.type_sector.value

    let errors = {}

    if (type == "OTHER") {
      if (type_definition == null)
        errors["type_definition_required"] = true

      if (type_sector == null)
        errors["type_sector_required"] = true
    }

    return errors
  }
}

// Art
export enum companyprofile_type_definition {
  ARCHITECT = 'ARCHITECT',
  TRADER = 'TRADER',
  MANUFACTURERS = 'MANUFACTURERS',
  PLANER = 'PLANER',
  SCHOOL = 'SCHOOL',
  OTHERS = 'OTHERS',
  ORG = 'ORG',
}

// Branche
export enum companyprofile_type_sector {

  // sorted for german translation alphabetically

  SEC15 = 'SEC15', // Arbeitskleidung
  SEC14 = 'SEC14', // Arbeitssicherheit
  SEC21 = 'SEC21', // Architektur
  SEC13 = 'SEC13', // Außenwandbekleidung

  SEC3 = 'SEC3', // Baustoffe
  SEC9 = 'SEC9', // Betriebliche Organisationsmittel
  SEC20 = 'SEC20', // Bildung

  SEC4 = 'SEC4', // Dachabdichtung
  SEC8 = 'SEC8', // Dachdeckungen
  SEC10 = 'SEC10', // Dachgeschossbau/Trockenbau
  SEC16 = 'SEC16', // Dienstleistungen

  SEC12 = 'SEC12', // Entwässerungen

  SEC1 = 'SEC1', // Fahrzeuge, Maschinen, Werkzeuge

  SEC11 = 'SEC11', // Gerüste, Leitern, Krane

  SEC19 = 'SEC19', // Holzbau
  SEC2 = 'SEC2', // Holz und Holzwerkstoffe

  SEC22 = 'SEC22', // Medien
  SEC23 = 'SEC23', // Messe

  SEC17 = 'SEC17', // Onlinedienste

  SEC18 = 'SEC18', // Software
  SEC5 = 'SEC5', // Sonstiges

  SEC6 = 'SEC6', // Verankerungen, Verbindungen, Befestigungen
  SEC7 = 'SEC7', // Wärme- und Schalldämmung
}

let checkhttps = {
  from: {
    map: (att: String) => {
      if (att == null || att == "")
        return undefined

      if (att.toLowerCase().startsWith("https://") || att.toLowerCase().startsWith("http://"))
        return att
      else return "https://" + att
    },
  },
}

export class CompanyProfile implements Profile {

  public profileType = ProfileTypeIdentifier.business

  @InFormGroup()
  @Serialize()
  public id: number

  @InFormGroup()
  @Serialize()
  public is_published: boolean

  @InFormGroup()
  @Serialize()
  public credits: number

  @InFormGroup()
  @Serialize()
  public is_partner: boolean

  // TYPES
  //////////////////////////////////////////

  @InFormGroup([Validators.required])
  @Serialize()
  public type: 'OTHER' | 'CARPENTER' | 'CARPENTER_PLUS'

  @InFormGroup()
  @Serialize()
  public type_definition: companyprofile_type_definition

  @InFormGroup()
  @Serialize()
  public type_sector: companyprofile_type_sector

  // currently mostly used for validators that concern more than one formcontrol
  public formOptions: AbstractControlOptions = {
    validators: [type_definition_VALID()],
  }

  // GENERAL INFO
  //////////////////////////////////////////

  @InFormGroup([Validators.required, Validators.minLength(2), Validators.maxLength(60)])
  @Serialize()
  public name: string

  @InFormGroup([Validators.required])
  @Serialize()
  public street: string

  @InFormGroup([Validators.required])
  @Serialize()
  public street_additional: string

  @InFormGroup([Validators.required])
  @Serialize()
  public zip: string

  @InFormGroup([Validators.required])
  @Serialize()
  public city: string

  @InFormGroup()
  @Serialize()
  public state: string

  @InFormGroup()
  @Serialize()
  public country: string

  @InFormGroup()
  @Serialize()
  public contact_name: string = ''

  @InFormGroup()
  @Serialize()
  public contact_position: string = ''

  @InFormGroup([Validators.email])
  @Serialize()
  public email: string

  @InFormGroup(standardvalidators.tel)
  @Serialize()
  public telephone: string

  @InFormGroup()
  @Serialize({
    from: {
      map: (website: string) => {
        return StaticHelper.prepareWebsite(website)
      }
    }
  })
  public website: string

  @InFormGroup()
  @Serialize({
    from: {
      map: (website: string) => {
        return StaticHelper.prepareWebsite(website)
      }
    }
  })
  public impressum: string

  // DETAILS
  //////////////////////////////////////////

  @InFormGroup()
  @Serialize()
  public service_products: string

  @InFormGroup([], true)
  @Serialize({
    from: {
      map: (json: string, ss: SerializationService) => {
        let arr = JSON.parse(json)

        if (!arr)
          arr = []

        return ss.deserializeMany(Contactperson, arr)
      },
      deps: [SerializationService],
    },
    to: {
      map: (contactpersons: Contactperson[], ss: SerializationService) => {
        if (Array.isArray(contactpersons) && contactpersons.length > 0) {

          // remove empty elements
          contactpersons = contactpersons.filter(c => !c.empty())

          return ss.serializeMany(contactpersons, Contactperson).then(arr => JSON.stringify(arr))
        }
        return null
      },
      deps: [SerializationService],
    },
  })
  public contactpersons: Contactperson[] = []

  @InFormGroup()
  @Serialize()
  public information_year_of_founding: string

  @InFormGroup()
  @Serialize()
  public information_opening_hours: string

  @InFormGroup()
  @Serialize()
  public information_products: string

  @InFormGroup()
  @Serialize()
  public information_about_us: string

  @InFormGroup()
  @Serialize()
  public information_news: string

  // IMAGES
  //////////////////////////////////////////

  @InFormGroup()
  @Serialize({
    from: {
      key: 'cached_images',
      map: (raw: any, svc: SerializationService, self: CompanyProfile) => {

        // deserialize images
        svc.deserializeMany(ComputedImage, raw)
          .then((images: ComputedImage[]) => {

            for (let c of images) {

              const imageDef = {
                small: c.fullUrl.cached,
                medium: c.fullUrl.cached,
                big: c.fullUrl.cached,
                description: self.getName() + " product image",
              }

              if (c.image.purpose_key == 'COMPANY_IMAGE')
                self.profileImage = c
              else if (c.image.purpose_key == 'COMPANY_WALL_IMAGE')
                self.profileWallImage = c

              else if (c.image.purpose_key == 'COMPANY_PRODUCT') {
                if (!self.productImages)
                  self.productImages = []
                self.productImages.push(c)
                self.productImagesGallery.push(imageDef)
              }
              else if (c.image.purpose_key == 'COMPANY_CERTIFICATE') {
                if (!self.certifacteImages)
                  self.certifacteImages = []
                self.certifacteImages.push(c)
                self.certifacteImagesGallery.push(imageDef)
              }
            }

            return images
          })
      },
      deps: [SerializationService]
    },
    to: 'cached_images'
  })
  public images: ComputedImage[]

  public profileImage: ComputedImage
  public profileWallImage: ComputedImage

  public productImages: ComputedImage[]
  public certifacteImages: ComputedImage[]

  public productImagesGallery: NgxGalleryImage[] = []
  public certifacteImagesGallery: NgxGalleryImage[] = []

  // SOCIAL
  //////////////////////////////////////////

  @InFormGroup()
  @Serialize(checkhttps)
  public facebook: string

  @InFormGroup()
  @Serialize(checkhttps)
  public instagram: string

  @InFormGroup()
  @Serialize(checkhttps)
  public linkedin: string

  @InFormGroup()
  @Serialize(checkhttps)
  public xing: string

  @InFormGroup()
  @Serialize(checkhttps)
  public youtube: string

  @InFormGroup()
  @Serialize(checkhttps)
  public twitter: string

  @Serialize({
      from: {
          deps: [SerializationService],
          map: async (raw: any[], svc: SerializationService) => {
              const promises = raw.map(entry => deserializePostMapper(entry, svc));
              return Promise.all(promises);
          },
      },
      to: false,
  })
  posts?: Post[];


  public isCertified() {
    return false;
  }

  public getName() {
    return this.name ? this.name : ''
  }

  public getGmapsAddress(): GMapAddress | null {

    if (this.street != null &&
      this.street_additional != null &&
      this.city != null &&
      this.zip != null &&
      this.state != null &&
      this.country != null) {

      return new GMapAddress({
        street: this.street,
        street_additional: this.street_additional,
        city: this.city,
        zip: this.zip,
        state: this.state,
        country: this.country,
      })
    }

    return null
  }

  // used to decide which tabs / blocks are not filled and should be hidden
  public transformToDisplayStructure() {
    return {

      info: {
        general_info: {
          type_sector: this.type_sector,
          information_products: this.information_products,
          information_year_of_founding: this.information_year_of_founding,
        },
        information_about_us: this.information_about_us,
        information_news: this.information_news
      },

      posts: this.posts,

      references: {
        service_products: this.service_products,
        productImages: this.productImages,
        certifacteImages: this.certifacteImages
      },

      contactpersons: {
        contactpersons: this.contactpersons
      },

      contact: {
        contact: {
          contact_name: this.contact_name,
          contact_position: this.contact_position,
          telephone: this.telephone,
          email: this.email,
          website: this.website,
        },
        address: {
          name: this.name,
          street: this.street,
          street_additional: this.street_additional,
          zip: this.zip,
          city: this.city,
          state: this.state,
          country: this.country,
          impressum: this.impressum,
        },
        information_opening_hours: this.information_opening_hours
      },

      socialmedia: {
        twitter: this.twitter,
        instagram: this.instagram,
        facebook: this.facebook,
        linkedin: this.linkedin,
        xing: this.xing,
        youtube: this.youtube,
      },
    }
  }

}
