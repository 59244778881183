import { DrawerService } from '@service/drawer.service'
import { Component, HostListener, OnInit } from '@angular/core'
import { UserService } from '@service/user/user.service'
import { MobileService } from '@service/mobile.service'
import { MenuStructureService } from '@service/menu-structure/menu-structure.service'
import { NavigationItem } from '@model/navigation-item/navigation-item'
import { ScrollService } from '@service/scroll.service'
import { HeaderService } from '@service/header.service'
import { CategoriesService } from '@service/categories.service'
import { HelperService } from '@service/helper.service'
import { environment } from '@environments/environment'
import { StateService } from '@service/state.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public environment = environment
  public navigationItemsDesktop: NavigationItem[] = null
  public navigationItemsMobile: NavigationItem[] = null
  public isShrink = false;

  constructor(
    public userService: UserService,
    public drawerService: DrawerService,
    public mobileService: MobileService,
    private menuStructureService: MenuStructureService,
    public ScrollService: ScrollService,
    public HeaderService: HeaderService,
    public MenuStructureService: MenuStructureService,
    public CategoriesService: CategoriesService,
    public HelperService: HelperService,
    public StateService: StateService
  ) { }

  public ngOnInit() {

    this.navigationItemsDesktop = this.menuStructureService.getMainMenuLong()

    this.navigationItemsMobile = this.menuStructureService.getMainMenuShort()

    this.mobileService.mobileObservable.subscribe(mobile => {
      if (!mobile) {
        this.drawerService.close()
      }
    })

    this.StateService.stateDataChanged.subscribe(() => {
      this.HeaderService.hideCreateTooltipNow()
    })
  }

  get isCreateTooltipVisible(): boolean {
    return this.HeaderService.showCreateTooltip
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollOffset = document.documentElement.scrollTop;

    if (scrollOffset > 10) {
      this.isShrink = true;
    } else {
      this.isShrink = false;
    }
  }
}
