<div class="navbar mobile" [ngClass]="{ 'hidden': isHidden }">
    <div class="navbtn" (click)="goBack()">
        <div>
            <i class="fal fa-arrow-alt-circle-left"></i>
        </div>
    </div>

    <div class="navbtn" [routerLink]="'/news'" routerLinkActive="active" (click)="scrollService.scrollToTop()">
        <div>
            <i class="fal fa-house"></i>
        </div>
    </div>



    <create-entry-button-with-tooltip [isMobileNavbar]="true"></create-entry-button-with-tooltip>

    <div class="navbtn">
        <app-notifications-bell [isMobileNavbar]="true"></app-notifications-bell>
    </div>



    <div class="navbtn" (appLoginClickGuard)="stateService.goto('/profile/about', {}, true,
          true)">
        <div>
            <i class="fas fa-user-circle"></i>
        </div>
    </div>
</div>